<template>
    <div>
        <div v-if="successMessage" class="alert alert-success" style="color: rgb(5, 20, 48)">
            {{ successMessage }}
        </div>
        <b-card>
            <b-row>
                <div class="col-12 mt-16">
                    <b-form @submit.prevent="addUser" v-if="show">
                        <div style="
                background-color: rgb(97, 116, 152);
                height: 32px;
                border-radius: 4px;
              ">
                            <h4 style="
                  color: rgb(223, 227, 238);
                  margin-left: 5px;
                  font-weight: bold;
                ">
                                Slider Information
                            </h4>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="URL:" label-for="url">
                                    <b-form-input id="url" type="url" placeholder="Enter URL" v-model="url" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Picture:" label-for="image">
                                    <div style="margin-left: 3px; margin-bottom: 15px">
                                        <!-- <img v-if="editedUser.image" :src="'https://wossbackend.singsavatech.com/' + image
                                            " alt="Picture" style="max-width: 100px; max-height: 100px" /> -->
                                            <img v-if="image" :src="'https://wossbackend.singsavatech.com/' + image" alt="Picture" style="max-width: 100px; max-height: 100px" />

                                        <input type="file" accept="image/*" id="image"
                                            @change="onProfilePictureChange" />
                                    </div>
                                </b-form-group>
                            </div>
                        </div>

                        <b-button type="submit" variant="primary" class="mb-8 mr-8" :disabled="isLoading">
                            <span v-if="!isLoading">Submit</span>
                            <b-spinner v-else class="mb-8 mr-8" variant="primary" small></b-spinner>
                        </b-button>
                    </b-form>
                </div>
                <div v-if="codeActive" class="col-12 mt-24 hljs-container" :class="{ active: codeActiveClass }">
                    <pre v-highlightjs>
            <code class="hljs html">
              {{ codeText }}
            </code>
          </pre>
                </div>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BButton,
    BForm,
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormSelect,
    BFormInput,
    BSpinner,
} from "bootstrap-vue";
import axios from "axios";
import { BToast } from "bootstrap-vue";
import code from "@/view/components/data-entry/form/code";

export default {
    data() {
        return {
            show: true,
            codeText: code.introduction,
            codeActive: false,
            codeActiveClass: false,
            selectedOption: "",
            inputField1: "",
            inputField2: "",
            inputField3: "",
            showModal: false,
            isLoading: false,

            uri: "",
            image: "",
            editedUser: { 
            image: "",
            url: "",
        }


        };
    },
    components: {
        BRow,
        BCol,
        BCard,
        BButton,
        BForm,
        BFormGroup,
        BFormCheckboxGroup,
        BFormCheckbox,
        BFormSelect,
        BFormInput,
        BToast, // Add this line
        BSpinner,
    },

    created() {

        axios
        const userId = this.$route.params.id;
        axios
            .get(`sliderShow/${userId}`)
            .then((response) => {

                this.editedUser = response.data.data;
                this.url = this.editedUser.url;
                this.image = this.editedUser.image;
            
            })
            .catch((error) => {
                console.error("Error fetching user data:", error);
            });

    },
    methods: {
        showMsgBoxOne() {
            debugger;
        },
        onSubmit(event) {
            event.preventDefault();
            alert(JSON.stringify(this.form));
            // debugger
        },
      
        addUser() {
                this.isLoading = true;
                const formData = new FormData();
                formData.append("url", this.url);
                formData.append("image", this.image); 
                
                axios
                    .post(`sliderUpdate/${this.editedUser.id}`, formData)
                    .then((response) => {
                        console.log(response.data);
                        this.$bvToast.toast("Slider Update successfully!", {
                            title: "Success",
                            variant: "success",
                            solid: true,
                            appendToast: true,
                            toaster: "b-toaster-top-right",
                            autoHideDelay: 5000,
                            variant: "primary",
                        });
                        this.isLoading = false;
                    })
                    .catch((error) => {
                        this.errors = error.response.data.errors;
                        console.log(error.response.data);
                        this.isLoading = false;
                    });
            },

        onProfilePictureChange(event) {
            const file = event.target.files[0];
            if (file) {
                // Set the selected file to the data property
                this.image = file;
            }
        },


        codeClick() {
            this.codeActive = !this.codeActive;
        },

        saveOwnCar() {
            this.showModal = false;
        },
    },
};
</script>